import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import '../../styles.css';
import { withFirebase } from '../Firebase';
import NavBar from '../NavBar';

class TopHoursPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      filterBy: 'OVERALL', // QUARTERLY, WEEKLY
      department: 'All',
      departments: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.groups().on('value', (snapshot) => {
      const groups = snapshot.val();

      const groupList = Object.keys(groups).map((key) => ({
        ...groups[key],
        name: key,
      }));

      this.setState({
        loading: false,
        departments: groupList,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.groups().off();
  }

  fillTable() {
    let res = [];
    let rows = [];
    let filter = '';

    switch (this.state.filterBy) {
      case 'OVERALL':
        filter = 'hours_total';
        break;
      case 'QUARTERLY':
        filter = 'hours_quarter';
        break;
      case 'WEEKLY':
        filter = 'hours_week';
        break;
      default:
        // Do nothing
        break;
    }

    this.state.departments.forEach((d) => {
      if (this.state.department === "All" || d.name === this.state.department) {
        let names = Object.keys(d);
        names = names.filter(e => e !== "name");
        let vals = Object.values(d);
        let hours = [];
        vals.forEach((v) => {
          if (v[filter] !== undefined) {
            hours.push(v[filter]); 
          }
        })
        let zipped = names.map(function(e, i) {
          return [e, hours[i]];
        });
        res = res.concat(zipped);
      }
    });
    res.sort((a, b) => {
      if (a[1] < b[1]) {
        return 1;
      }
      if (a[1] > b[1]) {
        return -1;
      }
      return 0;
    });
    for (let i = 0; i < res.length; i++) {
      rows.push(
        <tr key={res[i][0]}>
          <td>{res[i][0]}</td>
          <td>{res[i][1]}</td>
        </tr>
      );
    }
    return rows;
  }

  render() {
    const email = localStorage.getItem('email')
    if (!email) {
      window.location.pathname = '/login'
    }
    const filters = [
      { value: 'Overall', label: 'Overall' },
      { value: 'Quarterly', label: 'Quarterly' },
      { value: 'Weekly', label: 'Weekly' },
    ];
    let deptNames = [{value: 'All', label: 'All'}];
    this.state.departments.forEach((d) => {
      deptNames.push({ value: d.name, label: d.name });
    });
    return (
      <div>
        <NavBar isAdmin={localStorage.getItem('name') === 'scott'} />
        <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
          <h1>Top Hours</h1>
          <br />
          <br />
          <Form inline>
            <Form.Label>Department:</Form.Label>
            <Select
              id='dept'
              name='dept'
              options={deptNames}
              defaultValue={deptNames[0]}
              onChange={(event) => this.setState({ department: event.label })}
            />
            <br />
            <Form.Label>Filter By:</Form.Label>
            <Select
              id='filterBy'
              name='filterBy'
              options={filters}
              defaultValue={filters[0]}
              onChange={(event) =>
                this.setState({ filterBy: event.label.toUpperCase() })
              }
            />
          </Form>
          <br />
          <br />
          <div style={{ paddingRight: '6%' }}>
            <table className='table table-striped table-borderless'>
              <thead>
                <tr>
                  <th scope='col'>Name</th>
                  <th scope='col'>Hours</th>
                </tr>
              </thead>
              <tbody>{this.fillTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default withFirebase(TopHoursPage);
