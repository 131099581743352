// src/components/NavBar/index.js
import React, { Component } from 'react';
import LogoutButton from '../Logout';

class NavBar extends Component {
  render() {
    if (this.props.isAdmin) {
        return (
            <nav className='navbar navbar-expand-sm bg-info'>
            <div className='container-fluid'>
              <a className='navbar-brand' href='/checkin'>
                Check In/Out
              </a>
              <a className='navbar-brand' href='/tophours'>
                Top Hours
              </a>
              <a className='navbar-brand' href='/depthours'>
                Department Hours
              </a>
              <a className='navbar-brand' href='/admin'>
                Admin Functions
              </a>
              <a className='navbar-brand' href='/managetimeperiods'>
                Manage Time Periods
              </a>
              <a className='navbar-brand' href='/register'>
                Register a New User/Admin
              </a>
              <LogoutButton />
            </div>
          </nav>
        )
    }
    return (
        <nav className='navbar navbar-expand-sm bg-info'>
          <div className='container-fluid'>
            <a className='navbar-brand' href='/checkin'>
              Check In/Out
            </a>
            <a className='navbar-brand' href='/tophours'>
              Top Hours
            </a>
            <a className='navbar-brand' href='/depthours'>
              Department Hours
            </a>
            <LogoutButton />
          </div>
        </nav>
    );
  }
}

export default NavBar;
