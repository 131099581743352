import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import AdminPage from '../Admin';
import CheckinPage from '../Checkin';
import DeptHoursPage from '../DeptHours/DeptHours.js';
import LoginPage from '../Login/Login';
import RegisterPage from '../Register/Register';
import TimePeriodsPage from '../TimePeriods';
import TopHoursPage from '../TopHours/TopHours.js';
import { withFirebase } from '../Firebase';

class App extends Component {
  componentDidMount() {
    this.props.firebase.autoUpdateWeeklySum();
  }
  render() {
    return (
      <Router>
        <div>
          <Route exact path={ROUTES.HOME} component={LoginPage} />
          <Route path={ROUTES.ADMIN} component={AdminPage} />
          <Route path={ROUTES.LOGIN} component={LoginPage} />
          <Route path={ROUTES.REGISTER} component={RegisterPage} />
          <Route path={ROUTES.CHECKIN} component={CheckinPage} />
          <Route path={ROUTES.TIMEPERIODS} component={TimePeriodsPage} />
          <Route path={ROUTES.TOPHOURS} component={TopHoursPage} />
          <Route path={ROUTES.DEPTHOURS} component={DeptHoursPage} />
          <hr />
        </div>
      </Router>
    );
  }
}

export default withFirebase(App);
