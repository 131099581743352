// src/components/Logout/index.js
import React from 'react';
import { withFirebase } from '../Firebase';

const LogoutButton = ({ firebase }) => (
  <button
    type='button'
    class="btn btn-default navbar-btn pull-right"
    onClick={() => {
      firebase.doSignOut().then(() => {
        localStorage.removeItem('email')
        localStorage.removeItem('name')
        window.location.pathname = '/'
      })
      .catch((errorMsg) => {
        alert(errorMsg)
      });
    }}
  >
    Logout
  </button>
);

export default withFirebase(LogoutButton);
