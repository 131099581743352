// src/components/Register/index.js
import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import NavBar from '../NavBar';
import { withFirebase } from '../Firebase';

// Main page for new user registration
const RegisterPage = () => (
  <div className='flex-container-column'>
    <RegisterForm />
  </div>
);

// Form used to sign up for a new account
class RegisterFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      passwordOne: '',
      passwordTwo: '',
      error: '',
      authLevel: '',
    };
  }

  onSubmit = (event) => {
    const { email, passwordOne, passwordTwo, authLevel } = this.state;

    // This should be made more detailed!
    const invalid =
      passwordOne !== passwordTwo || passwordOne === '' || email === '';

    if (!invalid) {
      this.props.firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then(() => {
          if (authLevel === 'admin') {
            this.props.firebase.addToAdmin(email)
          }
          this.setState({
            email: '',
            passwordOne: '',
            passwordTwo: '',
            error: '',
            authLevel: '',
          });
          alert("successfully registered")
        })
        .catch((errorMsg) => {
          this.setState({ error: errorMsg.message });
        });
      
    } else {
      this.setState({
        error: 'Please make sure that all fields are filled out.',
      });
    }

    // Stops the GET request
    event.preventDefault();
  };

  // Sets the state's value to the event's value (i.e. updates the state to the filled in form value)
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const email = localStorage.getItem('email')
    if (!email || localStorage.getItem('name') !== 'scott') {
      window.location.pathname = '/checkin'
    }
    const options = [{value: 'user', label: 'user'}, {value: 'admin', label: 'admin'}]
    return (
      <div>
        <NavBar isAdmin={true} />
        <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
          <h1>Register a new User/Admin</h1>
          <form onSubmit={this.onSubmit} className='form-group'>
            <p>
              <input
                type='text'
                placeholder='Email'
                name='email'
                value={this.state.email}
                onChange={this.onChange}
                className='form-control'
              />{' '}
            </p>
            <p>
              <input
                type='password'
                placeholder='Enter Password'
                name='passwordOne'
                value={this.state.passwordOne}
                onChange={this.onChange}
                className='form-control'
              />{' '}
            </p>
            <p>
              <input
                type='password'
                placeholder='Confirm Password'
                name='passwordTwo'
                value={this.state.passwordTwo}
                onChange={this.onChange}
                className='form-control'
              />{' '}
            </p>
            <Select
                  id='authLevel'
                  name='authLevel'
                  options={options}
                  defaultValue={options[0]}
                  onChange={(event) =>
                    this.setState({ authLevel: event.label })
                  }
                />
            <div className='flex-container-row'>
              <button className='btn btn-primary' type='submit'>
                Register
              </button>
            </div>
            <br />
            {this.state.error && <p className='error-msg'>{this.state.error}</p>}
          </form>
        </div>
      </div>
    );
  }
}

const RegisterForm = withRouter(withFirebase(RegisterFormBase));

export default RegisterPage;
export { RegisterForm };
