import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import NavBar from '../NavBar';

class TimePeriodsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      cur_week: '',
      cur_quarter: '',
      changed_quarter: '',
      changed_week: '',
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.get_curWeek().on('value', (snapshot) => {
      var date = new Date(snapshot.val());
      var dateString = date.toLocaleDateString();
      this.setState({
        cur_week: dateString,
      });
    });

    this.props.firebase.get_curQuarter().on('value', (snapshot) => {
      var date = new Date(snapshot.val());
      var dateString = date.toLocaleDateString();
      this.setState({
        loading: false,
        cur_quarter: dateString,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.get_curQuarter().off();
    this.props.firebase.get_curWeek().off();
  }

  handleChangeQuarter = (event) => {
    this.setState({ changed_quarter: event.target.value });
  };

  handleChangeWeek = (event) => {
    this.setState({ changed_week: event.target.value });
  };

  //adjust the database total week/quarter hours accordingly
  onSubmitChangeQuarter = (event) => {
    let time = new Date().toLocaleDateString("en-US");
    this.props.firebase.updateHourSum(event.target.quarter.value, time, "quarter")
    event.preventDefault();
  };

  onSubmitChangeWeek = (event) => {
    let endTime = new Date(event.target.week.value)
    endTime.setDate(endTime.getDate() + 7);
    let endString = endTime.toLocaleDateString("en-US");
    this.props.firebase.updateHourSum(event.target.week.value, endString, "week")
    event.preventDefault();
  };

  render() {
    const email = localStorage.getItem('email')
    if (!email || localStorage.getItem('name') !== 'scott') {
      window.location.pathname = '/checkin'
    }
    const { loading, cur_quarter, cur_week } = this.state;
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    return (
      <div>
        <NavBar isAdmin={true} />
      <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
        <h1>Manage Time Periods</h1>
        <br />
        <br />
        {loading && <p>Loading Time Periods...</p>}
        <TimePeriods quarter={cur_quarter} week={cur_week} />
        <br />
        <br />
        <form onSubmit={this.onSubmitChangeQuarter}>
          <label>
            Quarter starts on:
          </label>
          <br />
            <input
              type='text'
              name='quarter'
              defaultValue={cur_quarter}
            />
          <button type='submit' className='btn btn-info' style={{ marginRight: '0.5%' }}>Change</button>
        </form>
        <br />
        <form onSubmit={this.onSubmitChangeWeek}>
          <label>
            Week now starts on a {weekday[new Date(cur_week).getDay()]} (auto updated)
            <br />
            To change, enter any Monday/Tuesday/... date:
          </label>
          <br />
            <input
              type='text'
              name='week'
              defaultValue={cur_week}
            />
          <button type='submit' className='btn btn-info' style={{ marginRight: '0.5%' }}>Change</button>
        </form>
      </div>
      </div>
    );
  }
}
//the initial current quarter/week has to be set up manually in the database
const TimePeriods = ({ quarter, week }) => (
  <ul>
    <li key='quarter'>
      <span>
        <strong>Current Quarter: </strong>
        {quarter}
      </span>
    </li>
    <li key='week'>
      <span>
        <strong>Current Week: </strong>
        {week}
      </span>
    </li>
  </ul>
);

export default withFirebase(TimePeriodsPage);
