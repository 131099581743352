// src/index.js
// This app follows this tutorial: https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import * as serviceWorker from './serviceWorker';
import './styles.css';

//import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  // Initialize Firebase exactly once, then pass the instance to the App
  // Individual components access Firebase with Firebase.Consumer
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
