// src/components/Admin/index.js
import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import NavBar from '../NavBar';

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departments: [],
      departmentNames: [],
      usersDisplay: false,
      deptsDisplay: false,
      showNameModal: false,
      showDepartmentModal: false,
      showHoursModal: false,
      showAddUserModal: false,
      showDeptNameModal: false,
      showAddDeptModal: false,
      nameModalOldName: '',
      nameModalDept: '',
      deptModalOldDept: '',
      deptModalUserName: '',
      deptNameModalOldName: '',
      hoursModalUserName: '',
      hoursModalDept: '',
    };
    this.handleOpenNameModal = this.handleOpenNameModal.bind(this);
    this.handleCloseNameModal = this.handleCloseNameModal.bind(this);
    this.onSubmitNameForm = this.onSubmitNameForm.bind(this);

    this.handleOpenDepartmentModal = this.handleOpenDepartmentModal.bind(this);
    this.handleCloseDepartmentModal = this.handleCloseDepartmentModal.bind(this);
    this.onSubmitDepartmentForm = this.onSubmitDepartmentForm.bind(this);

    this.handleOpenHoursModal = this.handleOpenHoursModal.bind(this);
    this.handleCloseHoursModal = this.handleCloseHoursModal.bind(this);
    this.onSubmitHoursForm = this.onSubmitHoursForm.bind(this);

    this.handleOpenAddUserModal = this.handleOpenAddUserModal.bind(this);
    this.handleCloseAddUserModal = this.handleCloseAddUserModal.bind(this);
    this.onSubmitAddUserForm = this.onSubmitAddUserForm.bind(this);

    this.handleOpenDeptNameModal = this.handleOpenDeptNameModal.bind(this);
    this.handleCloseDeptNameModal = this.handleCloseDeptNameModal.bind(this);
    this.onSubmitDeptNameForm = this.onSubmitDeptNameForm.bind(this);

    this.handleOpenAddDeptModal = this.handleOpenAddDeptModal.bind(this);
    this.handleCloseAddDeptModal = this.handleCloseAddDeptModal.bind(this);
    this.onSubmitAddDeptForm = this.onSubmitAddDeptForm.bind(this);
  }

  componentDidMount() {

    this.props.firebase.groups().on('value', (snapshot) => {
      const groups = snapshot.val();

      const groupList = Object.keys(groups).map((key) => ({
        ...groups[key],
        name: key,
      }));

      let departmentNames = []
      groupList.forEach((d) => {
        departmentNames.push({ value: d.name, label: d.name })
      })

      this.setState({
        departments: groupList,
        departmentNames
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.groups().off();
  }

  handleOpenNameModal (oldName, dept) {
    this.setState({ showNameModal: true, nameModalOldName: oldName, nameModalDept: dept });
  }

  handleCloseNameModal () {
    this.setState({ showNameModal: false });
  }

  onSubmitNameForm = (event) => {
    let oldName = this.state.nameModalOldName;
    let newName = event.target.newName.value;
    let dept = this.state.nameModalDept;
    this.props.firebase.editUserName(oldName, newName, dept);
    event.preventDefault();
    this.handleCloseNameModal();
  }

  handleOpenDepartmentModal (userName, oldDeptName) {
    this.setState({ showDepartmentModal: true, deptModalOldDept: oldDeptName, deptModalUserName: userName });
  }

  handleCloseDepartmentModal () {
    this.setState({ showDepartmentModal: false });
  }

  onSubmitDepartmentForm = (event) => {
    let oldDept = this.state.deptModalOldDept;
    let userName = this.state.deptModalUserName;
    let newDept = event.target.dept.value;
    this.props.firebase.editUserDept(oldDept, newDept, userName);
    event.preventDefault();
    this.handleCloseDepartmentModal();
  }

  handleOpenHoursModal (username, dept) {
    this.setState({ showHoursModal: true, hoursModalUserName: username, hoursModalDept: dept });
  }

  handleCloseHoursModal () {
    this.setState({ showHoursModal: false });
  }

  onSubmitHoursForm = (event) => {
    let user = this.state.hoursModalUserName
    let dept = this.state.hoursModalDept
    let op = event.target.op.value;
    let date = event.target.date.value;
    let hours = event.target.hours.value;
    if (op === "delete") {
      hours = "-"+hours   
    }
    this.props.firebase.editHoursEntry(user, dept, date, hours)
    event.preventDefault();
    this.handleCloseHoursModal();
  }

  handleOpenAddUserModal () {
    this.setState({ showAddUserModal: true });
  }

  handleCloseAddUserModal () {
    this.setState({ showAddUserModal: false });
  }

  onSubmitAddUserForm = (event) => {
    let first = event.target.firstName.value
    let last = event.target.lastName.value
    let dept = event.target.dept.value
    this.props.firebase.userCreation(first, last, dept)
    event.preventDefault();
    this.handleCloseAddUserModal();
  }

  handleOpenDeptNameModal (oldName) {
    this.setState({ showDeptNameModal: true, deptNameModalOldName: oldName });
  }

  handleCloseDeptNameModal () {
    this.setState({ showDeptNameModal: false });
  }

  onSubmitDeptNameForm = (event) => {
    this.props.firebase.editDeptName(this.state.deptNameModalOldName, event.target.name.value)
    event.preventDefault();
    this.handleCloseDeptNameModal();
  }

  handleOpenAddDeptModal () {
    this.setState({ showAddDeptModal: true });
  }

  handleCloseAddDeptModal () {
    this.setState({ showAddDeptModal: false });
  }

  handleDeleteUser = (name, dept) => {
    this.props.firebase.deleteUser(dept, name)
  }

  onSubmitAddDeptForm = (event) => {
    this.props.firebase.addDept(event.target.name.value)
    event.preventDefault();
    this.handleCloseAddDeptModal();
  }

  addUserModal = () => {
    return (
      <Modal
      show={this.state.showAddUserModal}
      size="sm"
      centered
      animation={false}
      style={{top:'30%', height: '400px'}}
    >
    <h2 style={{marginLeft:'10%'}}>Add User</h2>
    <form onSubmit={this.onSubmitAddUserForm} className='form-group'>
    <p>
      User's First Name:
      <br />
      <input
        type='text'
        placeholder='First name...'
        name='firstName'
        style={{marginLeft:'20%'}}
      />
    </p>
    <p>
      User's Last Name:
      <br />
      <input
        type='text'
        placeholder='Last name...'
        name='lastName'
        style={{marginLeft:'20%'}}
      />
    </p>
    <p>
      Select user's department:
      <br />
    <Select
              id='dept'
              name='dept'
              options={this.state.departmentNames}
              defaultValue={this.state.departmentNames[0]}
            />
    </p>
    <button className='btn btn-success' type='submit' style={{marginLeft:'35%'}}>Submit</button>
    </form>
      <Modal.Footer>
        <button className='btn btn-info' onClick={this.handleCloseAddUserModal}>Close</button>
      </Modal.Footer>
    </Modal>
    )
  }

  editDepartmentModal = () => {
    return (
      <Modal
      show={this.state.showDepartmentModal}
      size="sm"
      centered
      animation={false}
      style={{top:'30%', height: '300px'}}
    >
    <h2 style={{marginLeft:'10%'}}>Edit User Department</h2>
    <form onSubmit={this.onSubmitDepartmentForm} className='form-group'>
    <p>
    <Select
              id='dept'
              name='dept'
              options={this.state.departmentNames}
              defaultValue={this.state.departmentNames[0]}
            />
    </p>
    <button className='btn btn-success' type='submit' style={{marginLeft:'35%'}}>Submit</button>
    </form>
      <Modal.Footer>
        <button className='btn btn-info' onClick={this.handleCloseDepartmentModal}>Close</button>
      </Modal.Footer>
    </Modal>
    )
  }

  editHoursModal = () => {
    let operations = [{value: 'add', label: 'Add Hours'}, {value: 'delete', label: 'Delete Hours'}];
    let time = new Date().toLocaleDateString("en-US");
    return (
      <Modal
      show={this.state.showHoursModal}
      size="sm"
      centered
      animation={false}
      style={{top:'30%', height: '500px'}}
    >
    <h2 style={{marginLeft:'10%'}}>Edit User Hours</h2>
    <form onSubmit={this.onSubmitHoursForm} className='form-group'>
      <p> Add or Delete Hours:
        <br />
        <Select
              id='op'
              name='op'
              options={operations}
              defaultValue={operations[0]}
      /></p>
    <p>
      As of date:
      <br />
      <input
        type='text'
        placeholder='Date...'
        name='date'
        defaultValue={time}
        style={{marginLeft:'20%'}}
      />
    </p>
    <p>
      Hours:
      <br />
      <input
        type='text'
        placeholder='Hours...'
        name='hours'
        style={{marginLeft:'20%'}}
      />
    </p>
    <button className='btn btn-success' type='submit' style={{marginLeft:'35%'}}>Submit</button>
    </form>
      <Modal.Footer>
        <button className='btn btn-info' onClick={this.handleCloseHoursModal}>Close</button>
      </Modal.Footer>
    </Modal>
    )
  }
  
  editNameModal = () => {
    return (
      <Modal
      show={this.state.showNameModal}
      size="sm"
      centered
      animation={false}
      style={{top:'30%', height: '300px'}}
    >
    <h2 style={{marginLeft:'10%'}}>Edit User Name</h2>
    <form onSubmit={this.onSubmitNameForm} className='form-group'>
    <p>
      <input
        type='text'
        placeholder='Name...'
        name='newName'
        defaultValue={this.state.nameModalOldName}
        style={{marginLeft:'20%'}}
      />
    </p>
    <button className='btn btn-success' type='submit' style={{marginLeft:'35%'}}>Submit</button>
    </form>
      <Modal.Footer>
        <button className='btn btn-info' onClick={this.handleCloseNameModal}>Close</button>
      </Modal.Footer>
    </Modal>
    )
  }

  editDeptNameModal = () => {
    return (
      <Modal
      show={this.state.showDeptNameModal}
      size="sm"
      centered
      animation={false}
      style={{top:'30%', height: '300px'}}
    >
    <h2 style={{marginLeft:'10%'}}>Edit Department Name</h2>
    <form onSubmit={this.onSubmitDeptNameForm} className='form-group'>
    <p>
      <input
        type='text'
        placeholder='Name...'
        name='name'
        defaultValue={this.state.deptNameModalOldName}
        style={{marginLeft:'20%'}}
      />
    </p>
    <button className='btn btn-success' type='submit' style={{marginLeft:'35%'}}>Submit</button>
    </form>
      <Modal.Footer>
        <button className='btn btn-info' onClick={this.handleCloseDeptNameModal}>Close</button>
      </Modal.Footer>
    </Modal>
    )
  }

  addDeptNameModal = () => {
    return (
      <Modal
      show={this.state.showAddDeptModal}
      size="sm"
      centered
      animation={false}
      style={{top:'30%', height: '300px'}}
    >
    <h2 style={{marginLeft:'10%'}}>Add Department Name</h2>
    <form onSubmit={this.onSubmitAddDeptForm} className='form-group'>
    <p>
      <input
        type='text'
        placeholder='Name...'
        name='name'
        style={{marginLeft:'20%'}}
      />
    </p>
    <button className='btn btn-success' type='submit' style={{marginLeft:'35%'}}>Submit</button>
    </form>
      <Modal.Footer>
        <button className='btn btn-info' onClick={this.handleCloseAddDeptModal}>Close</button>
      </Modal.Footer>
    </Modal>
    )
  }

  fillUsersTable() {
    // const { users } = this.state;
    let rows = [];
    let res = []
    this.state.departments.forEach((d) => {
      let deptName = d.name
      let names = Object.keys(d);
      names = names.filter(e => e !== "name");

      let vals = Object.values(d);
      let hours = [];
      vals.forEach((v) => {
        let allHours = []
        let filterList = ['hours_week', 'hours_quarter', 'hours_total']
        filterList.forEach((f) => {
          if (v[f] !== undefined) {
            allHours.push(v[f]); 
          }
        })
        if (allHours.length) {
          hours.push(allHours)
        }
      })
      let zipped = names.map(function(e, i) {
        return [e, deptName, hours[i]].flat();
      });
      res = res.concat(zipped);
    });

    for (let i = 0; i < res.length; i++) {
      const name = res[i][0];
      const dept = res[i][1];
      rows.push(
        <tr key={res[i][0]}>
          <td>{res[i][0]}</td>
          <td>
            <div>
            <button className='btn btn-info' style={{ marginRight: '0.5%' }} onClick={()=>{
              this.handleOpenNameModal(name, dept);
            }}>
              EDIT
            </button>
            {this.editNameModal()}
            </div>
          </td>
          <td>{res[i][1]}</td>
          <td>
            <div>
              <button className='btn btn-info' style={{ marginRight: '0.5%' }} onClick={()=>{
              this.handleOpenDepartmentModal(name, dept);
            }}>
                EDIT
              </button>
              {this.editDepartmentModal()}
            </div>
          </td>
          <td>{res[i][2]}</td>
          <td>{res[i][3]}</td>
          <td>{res[i][4]}</td>
          <td>
            <div>
            <button className='btn btn-info' style={{ marginRight: '0.5%' }} onClick={() => {
              this.handleOpenHoursModal(name, dept)
              }}>
              EDIT HOURS
            </button>
            {this.editHoursModal()}
            </div>
          </td>
          <td>
            <button className='btn btn-danger' style={{ marginRight: '0.5%' }} onClick={() => {
              this.handleDeleteUser(name, dept)
            }}>
              DELETE USER
            </button>
          </td>
        </tr>
      );
    }
    return rows;
  }

  fillDeptsTable() {
    const { departments } = this.state;
    let rows = [];

    for (let i = 0; i < departments.length; i++) {
      const deptName = departments[i].name;
      rows.push(
        <tr key={departments[i].name}>
          <td>{departments[i].name}</td>
          <td>
            <div>
            <button className='btn btn-info' style={{ marginRight: '0.5%' }} onClick={() => {
              this.handleOpenDeptNameModal(deptName);
            }}>
              EDIT
            </button>
            {this.editDeptNameModal()}
            </div>
          </td>
        </tr>
      );
    }
    return rows;
  }

  render() {
    const email = localStorage.getItem('email')
    if (!email || localStorage.getItem('name') !== 'scott') {
      window.location.pathname = '/checkin'
    }

    const { usersDisplay, deptsDisplay } = this.state;
    return (
      <div>
        <NavBar isAdmin={true} />

        <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
          <h1>Admin Functions</h1>
          <br /> <br />
          <button
            className='btn btn-primary btn-block btn-lg'
            onClick={(event) => {
              this.setState({ usersDisplay: !usersDisplay });
            }}
          >
            Manage Users
          </button>
          {usersDisplay && (
            <div style={{ paddingRight: '6%' }}>
              <br />
              <button className='btn btn-success' style={{ float: 'right' }} onClick={this.handleOpenAddUserModal}>
                ADD USER
              </button>
              {this.addUserModal()}
              <table className='table table-striped table-borderless'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Edit Name</th>
                    <th>Department</th>
                    <th>Edit Department</th>
                    <th>Weekly Hours</th>
                    <th>Quarterly Hours</th>
                    <th>Total Hours</th>
                    <th>Manage Hours</th>
                    <th>Manage User</th>
                  </tr>
                </thead>
                <tbody>{this.fillUsersTable()}</tbody>
              </table>
            </div>
          )}
          <br /> <br />
          <button
            className='btn btn-primary btn-block btn-lg'
            onClick={(event) => {
              this.setState({ deptsDisplay: !deptsDisplay });
            }}
          >
            Manage Departments
          </button>
          {deptsDisplay && (
            <div style={{ paddingRight: '6%' }}>
              <br />
              <button className='btn btn-success' style={{ float: 'right' }} onClick={this.handleOpenAddDeptModal}>
                ADD DEPARTMENT
              </button>
              {this.addDeptNameModal()}
              <table className='table table-striped table-borderless'>
                <thead>
                  <tr>
                    <th>Department</th>
                    <th>Functions</th>
                  </tr>
                </thead>
                <tbody>{this.fillDeptsTable()}</tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withFirebase(AdminPage);
