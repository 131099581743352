// src/components/Firebase/firebase.js
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

// Zihan's firebase config. For testing purpose only
// let firebaseConfig = {
//   apiKey: "AIzaSyAtcOJwbPYjVg-8Ewhje2-taLdbSoi_Yn8",
//   authDomain: "time-b4f06.firebaseapp.com",
//   databaseURL: "https://time-b4f06.firebaseio.com",
//   projectId: "time-b4f06",
//   storageBucket: "time-b4f06.appspot.com",
//   messagingSenderId: "668737202840",
//   appId: "1:668737202840:web:4017685d0bc80a3f923eed",
//   measurementId: "G-3PCMHBQPKJ"
// };

var firebaseConfig = {
  apiKey: 'AIzaSyC7Yr4a7dPSxC5K247U26WPZ8xGOiCI6V8',
  authDomain: 'bruinlife-time.firebaseapp.com',
  databaseURL: 'https://bruinlife-time.firebaseio.com',
  projectId: 'bruinlife-time',
  storageBucket: 'bruinlife-time.appspot.com',
  messagingSenderId: '512145130262',
  appId: '1:512145130262:web:acbb812e4dea1f873a8637',
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.database();
  }

  // AUTHENTICATION METHODS
  // Asynchronously defer authentication to Firebase Auth
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  user = (uid) => this.db.ref(`uq${uid}`);

  // DATABASE METHODS
  // Users

  addToAdmin(email) {
    this.db.ref('/administrators').get().then((snapshot) => {
      if (snapshot.exists()) {
        let allAdmins = snapshot.val();
        allAdmins.push(email)
        let updates = {}
        updates['/administrators/'] = allAdmins
        this.db.ref().update(updates);
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  administrators = () => this.db.ref('/administrators');

  curGroup = () => this.db.ref('/');

  get_curWeek = () => this.db.ref('/cur_week');

  get_curQuarter = () => this.db.ref('/cur_quarter');

  groups = () => this.db.ref('groupList');

  userCreation(firstname, lastname, group) {
    let parsedName =
      lastname[0].toUpperCase() +
      lastname.slice(1) + ', ' +
      firstname[0].toUpperCase() +
      firstname.slice(1);
    let ref = this.db.ref('groupList/' + group + '/' + parsedName);
    ref.update({
      hours_week: 0,
      hours_quarter: 0,
      hours_total: 0,
      checkin_status: false,
      most_recent_checkin: '',
    });
    return;
  }

  editUserDept(oldDept, newDept, userName) {
    let oldUserRef = this.db.ref('/groupList/' + oldDept);
    let newDeptRef = this.db.ref('/groupList/' + newDept + '/' + userName);
    oldUserRef.child(userName).once('value').then(function(snap) {
      let userval = snap.val();
      let oldDeptUpdate = {};
      oldDeptUpdate[userName] = null;
      oldUserRef.update(oldDeptUpdate);
      newDeptRef.set(userval)
    });
  }

  editUserName(oldName, newName, dept) {
    let userRef = this.db.ref('/groupList/' + dept);
    userRef.child(oldName).once('value').then(function(snap) {
      let data = snap.val();
      let update = {};
      update[oldName] = null;
      update[newName] = data;
      return userRef.update(update);
    });
  }

  deleteUser(dept, name) {
    this.db.ref('/groupList/' + dept + '/' + name).set(null); 
  }

  editDeptName(oldName, newName) {
    let groupRef = this.db.ref('/groupList');
    groupRef.child(oldName).once('value').then(function(snap) {
      let data = snap.val();
      let update = {};
      update[oldName] = null;
      update[newName] = data;
      return groupRef.update(update);
    });
  }

  addDept(name) {
    this.db.ref('/groupList/' + name).set(true); 
  }

  autoUpdateWeeklySum() {
    console.log("hi")
    this.db.ref('/cur_week').get().then((snapshot) => {
      if (snapshot.exists()) {
        let oldDate = new Date(snapshot.val());
        let newDate = new Date(snapshot.val());
        let cur = new Date();
        // To calculate the time difference of two dates
        let diff_in_time = cur.getTime() - newDate.getTime();
        // To calculate the no. of days between two dates
        let diff_in_days = (diff_in_time / (1000 * 3600 * 24)).toFixed(0);
        while (diff_in_days > 6) {
          newDate.setDate(newDate.getDate() + 7);
          diff_in_time = cur.getTime() - newDate.getTime();
          diff_in_days = (diff_in_time / (1000 * 3600 * 24)).toFixed(0);
        }
        if (oldDate.getTime() !== newDate.getTime()) {
          this.updateHourSum(newDate.getTime(), cur.getTime(), "week");
        }
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  updateHourSum(startDate, endDate, freq) {
    let start = new Date(startDate);
    start.setHours(0);
    let end = new Date(endDate);
    end.setHours(23, 59);

    this.db.ref('/groupList').get().then((snapshot) => {
      if (snapshot.exists()) {
        const groups = snapshot.val();
        const groupList = Object.keys(groups).map((key) => ({
          ...groups[key],
          name: key,
        }));
        
        groupList.forEach((dep) => {
          let names = Object.keys(dep);
          let val = Object.values(dep);
          for (let i = 0; i < names.length; i++) {
            if (names[i] !== 'name') {
              let res = val[i].checkinEntries
              let total = 0;
              for (let key in res) {
                let record = res[key]
                if (record.checkoutTime) {
                  let cur = new Date(record.checkoutTime).getTime()
                  if (start.getTime() < cur && cur < end.getTime()) {
                    total += parseFloat(record.timeElapsed)
                  }
                }
              }
              let userRef = this.db.ref('/groupList/' + dep.name + '/' + names[i])
              if (freq === "week") {
                userRef.update({
                  hours_week: total,
                });
                let updates = {}
                updates['/cur_week'] = start
                this.db.ref().update(updates);
              } else if (freq === "quarter") {
                userRef.update({
                  hours_quarter: total,
                });
                let updates = {}
                updates['/cur_quarter'] = start
                this.db.ref().update(updates);
              }
            }
          }
        })
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  createCheckInEntries(username, dept) {
    let newCheckinEntry = {
      checkinTime: new Date(),
      checkoutTime: '',
      timeElapsed: 0,
    };

    let newCheckinEntryKey = this.db
      .ref('groupList/' + dept + '/' + username)
      .child('checkinEntries')
      .push().key;

    let updates = {};
    updates[
      '/groupList/' +
        dept +
        '/' +
        username +
        '/checkinEntries/' +
        newCheckinEntryKey
    ] = newCheckinEntry;

    this.db.ref().update(updates);
    this.db
      .ref('/groupList/' + dept + '/' + username)
      .update({ checkin_status: true });
      this.db
      .ref('/groupList/' + dept + '/' + username)
      .update({ most_recent_checkin: newCheckinEntryKey });
  }

  createCheckOutEntries = (username, entry, dept) => {
    let endTime = new Date();
    let startTime;
    this.db
      .ref('/groupList/' + dept + '/' + username + '/checkinEntries/' + entry)
      .on('value', (snapshot) => {
        startTime = new Date(snapshot.val().checkinTime);
      });

    let timeDiff = endTime - startTime; // in miliseconds
    timeDiff /= 3600000; // get in hours
    let hours = timeDiff.toFixed(3); //round to 3 decimals

    this.db
      .ref('/groupList/' + dept + '/' + username + '/checkinEntries/' + entry)
      .update({
        checkoutTime: endTime,
        timeElapsed: hours,
      });

    let totalHours, hoursWeek, hoursQuarter;
    this.db
      .ref('/groupList/' + dept + '/' + username)
      .on('value', (snapshot) => {
        totalHours = parseFloat(snapshot.val().hours_total);
        hoursWeek = parseFloat(snapshot.val().hours_week);
        hoursQuarter = parseFloat(snapshot.val().hours_quarter);
      });

    totalHours += parseFloat(hours);
    hoursWeek += parseFloat(hours);
    hoursQuarter += parseFloat(hours);

    this.db.ref('/groupList/' + dept + '/' + username).update({
      checkin_status: false,
      hours_total: totalHours,
      hours_week: hoursWeek,
      hours_quarter: hoursQuarter,
    });
  };

  editHoursEntry = (username, dept, date, hours) => {
    let time = new Date(date);
    time.setHours(12);
    let newCheckinEntry = {
      checkinTime: time,
      checkoutTime: time,
      timeElapsed: hours,
    };

    let newCheckinEntryKey = this.db
      .ref('groupList/' + dept + '/' + username)
      .child('checkinEntries')
      .push().key;

    let updates = {};
    updates[
      '/groupList/' +
        dept +
        '/' +
        username +
        '/checkinEntries/' +
        newCheckinEntryKey
    ] = newCheckinEntry;

    this.db.ref().update(updates);

    let totalHours, hoursWeek, hoursQuarter;
    this.db
      .ref('/groupList/' + dept + '/' + username)
      .on('value', (snapshot) => {
        totalHours = parseFloat(snapshot.val().hours_total);
        hoursWeek = parseFloat(snapshot.val().hours_week);
        hoursQuarter = parseFloat(snapshot.val().hours_quarter);
      });
    totalHours += parseFloat(hours);
    hoursWeek += parseFloat(hours);
    hoursQuarter += parseFloat(hours);
    this.db.ref('/groupList/' + dept + '/' + username).update({
      hours_total: totalHours,
      hours_week: hoursWeek,
      hours_quarter: hoursQuarter,
    });
  };
}

export default Firebase;
