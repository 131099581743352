import React, { Component } from 'react';
import Select from 'react-select';
import { withFirebase } from '../Firebase';
import NavBar from '../NavBar';

class CheckinPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
      checkedInUsers: [],
      userSelected: '',
      buttonText: 'CHECK IN',
      seeWhosCheckedIn: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    let allUsers = [];
    this.props.firebase.groups().on('value', (snapshot) => {
      const groups = snapshot.val();

      const groupList = Object.keys(groups).map((key) => ({
        ...groups[key],
      }));

      groupList.forEach((eachGroup) => {
        const userList = Object.keys(eachGroup).map((key) => ({
          ...eachGroup[key],
          uid: key,
        }));

        allUsers.push(userList);
      });

      allUsers = allUsers.flat();

      this.setState({
        loading: false,
        users: allUsers,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.groups().off();
  }

  onSubmit = () => {
    const selected = this.state.users.filter(
      (user) => user.uid === this.state.userSelected
    );
    this.state.buttonText === 'CHECK IN'
      ? this.props.firebase.createCheckInEntries(
          this.state.userSelected,
          this.state.selectedDept
        )
      : this.props.firebase.createCheckOutEntries(
          this.state.userSelected,
          selected[0].most_recent_checkin,
          this.state.selectedDept
        );
  };

  setUser = (event) => {
    this.setState({ userSelected: event.label }); 
    const selected = this.state.users.filter(
      (user) => user.uid === event.label 
    );
    // assume for now there will be no user with same names
    selected[0].checkin_status
      ? this.setState({ buttonText: 'CHECK OUT' })
      : this.setState({ buttonText: 'CHECK IN' });

      this.props.firebase.groups().on('value', (snapshot) => {
      const groups = snapshot.val();

      let deptList = Object.keys(groups);
      for (let i = 0; i < deptList.length; i++) {
        if (selected[0].uid in groups[deptList[i]]) {
          this.setState({
            selectedDept: deptList[i],
          });
        }
      }
    });
  };

  getCheckedInUser = () => {
    const checkedinUserList = this.state.users.filter(
      (user) => user.checkin_status
    );
    this.setState({
      checkedInUsers: checkedinUserList,
    });
    this.setState({
      seeWhosCheckedIn: true,
    });
  };

  render() {
    const email = localStorage.getItem('email')
    if (!email) {
      window.location.pathname = '/login'
    }
    const { loading, users, checkedInUsers } = this.state;

    let names = [];
    for (let i = 0; i < users.length; i++) names.push({ label: users[i].uid });


    return (
      <body>
        <NavBar isAdmin={localStorage.getItem('name') === 'scott'} />

        <div className='flex-container-column'>
          <h1>Check Yourself In/Out!</h1>
          <br />
          {loading && <p>Loading Users...</p>}
          <form onSubmit={this.onSubmit} className='flex-container-column'>
            <br />
            <div style={{ minWidth: '200px' }}>
              <Select
                options={names}
                placeholder='Select your name.'
                onChange={this.setUser}
              />
            </div>
            <br />
            <button className='btn btn-primary' type='submit'>
              {this.state.buttonText}
            </button>
          </form>
          <br />
          <button className='btn btn-primary' onClick={this.getCheckedInUser}>
            See Who's Checked In
          </button>
          {this.state.seeWhosCheckedIn &&
            (checkedInUsers.length ? (
              <ListUser users={checkedInUsers} />
            ) : (
              <NoUserfunc />
            ))}
          <br />
          <br />
        </div>
      </body>
    );
  }
}

const NoUserfunc = () => <h5>No currently checked in users!</h5>;

const ListUser = ({ users }) => (
  <ul>
    {users.map((user) => (
      <li key={user.uid}>
        <span>{user.uid}</span>
      </li>
    ))}
  </ul>
);

export default withFirebase(CheckinPage);
