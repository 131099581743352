// src/components/Login/index.js
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import '../../styles.css';
import { withFirebase } from '../Firebase';

const LoginPage = () => (
  <div className='flex-container-column'>
    <h1>Login</h1>
    <br />
    <LoginForm />
  </div>
);

class LoginFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: '',
      adminList: [],
    };
  }

  componentDidMount() {
    this.props.firebase.administrators().on('value', (snapshot) => {
      const adminList = snapshot.val();
      this.setState({
        adminList: adminList,
      });
    });
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    const invalid = email === '' || password === '';

    if (!invalid) {
      this.props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(() => {
          localStorage.setItem('email', email)
          let res = this.state.adminList.includes(email)
          if (res) {
            localStorage.setItem('name', 'scott')
          } else {
            localStorage.setItem('name', 'name')
          }
          this.setState({
            email: '',
            password: '',
            error: '',
          });
          this.props.history.push(ROUTES.CHECKIN);
        })
        .catch((errorMsg) => {
          this.setState({ error: errorMsg.message });
        });
    } else {
      this.setState({
        error: 'Please make sure that all fields are filled out.',
      });
    }

    // Stops the GET request
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <form onSubmit={this.onSubmit} className='form-group'>
        <p>
          <input
            type='text'
            value={this.state.email}
            name='email'
            placeholder='Email'
            onChange={this.onChange}
            className='form-control'
          />
        </p>
        <p>
          <input
            type='password'
            value={this.state.password}
            name='password'
            placeholder='Password'
            onChange={this.onChange}
            className='form-control'
          />
        </p>
        <div className='flex-container-row'>
          <button className='btn btn-primary' type='submit'>
            Login
          </button>
        </div>
        <br />

        {this.state.error && (
          <p
            className='flex-container-row error-msg'
            style={{
              overflowWrap: 'normal',
              maxWidth: '173px',
              textAlign: 'center',
            }}
          >
            {this.state.error}
          </p>
        )}
      </form>
    );
  }
}

const LoginForm = withRouter(withFirebase(LoginFormBase));

export default LoginPage;
export { LoginForm };
