import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import '../../styles.css';
import { withFirebase } from '../Firebase';
import NavBar from '../NavBar';

class DeptHoursPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      // users: [],
      filterBy: 'OVERALL', // QUARTERLY, WEEKLY
      departments: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.groups().on('value', (snapshot) => {
      const groups = snapshot.val();

      const groupList = Object.keys(groups).map((key) => ({
        ...groups[key],
        name: key,
      }));

      this.setState({
        loading: false,
        departments: groupList,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.groups().off();
  }

  parseDepartmentHours(dep) {
    let resList = [];
    let filter = '';
    switch (this.state.filterBy) {
      case 'OVERALL':
        filter = 'hours_total';
        break;
      case 'QUARTERLY':
        filter = 'hours_quarter';
        break;
      case 'WEEKLY':
        filter = 'hours_week';
        break;
      default:
        // Do nothing
        break;
    }

    dep.forEach((dep) => {
      let val = Object.values(dep);

      let depName = val[val.length - 1];
      let employees = val.slice(0, -1);

      let sumHours = 0;

      for (let i = 0; i < employees.length; i++) {
        if (employees[i][filter] !== undefined) {
          sumHours += employees[i][filter];          
        }
      }
      let divideBy = employees.length
      if (divideBy === 0) {
        divideBy = 1 // handle the special case of new department
      }
      let averageHours = sumHours / divideBy;

      resList.push([depName, sumHours.toFixed(2), averageHours.toFixed(2)]);
    });

    // sort by sum hours
    resList.sort((a, b) => {
      if (a[1] < b[1]) {
        return 1;
      }
      if (a[1] > b[1]) {
        return -1;
      }
      return 0;
    });

    return resList;
  }

  fillTable() {
    let rows = [];

    let result = this.parseDepartmentHours(this.state.departments);

    for (let i = 0; i < result.length; i++) {
      rows.push(
        <tr key={result[i][0]}>
          <td>{result[i][0]}</td>
          <td>{result[i][1]}</td>
          <td>{result[i][2]}</td>
        </tr>
      );
    }

    return rows;
  }

  render() {
    const email = localStorage.getItem('email')
    if (!email) {
      window.location.pathname = '/login'
    }

    const filters = [
      { value: 'Overall', label: 'Overall' },
      { value: 'Quarterly', label: 'Quarterly' },
      { value: 'Weekly', label: 'Weekly' },
    ];
  
    return (
      <div>
        <NavBar isAdmin={localStorage.getItem('name') === 'scott'} />

        <div style={{ paddingLeft: '3%', paddingRight: '3%' }}>
          <h1>Department Hours</h1>
          <br />
          <br />
          <Form inline>
            <Form.Label>Filter By:</Form.Label>
            <Select
              id='selection'
              name='selection'
              options={filters}
              // placeholder={filters}
              defaultValue={filters[0]}
              onChange={(event) =>
                this.setState({ filterBy: event.label.toUpperCase() })
              }
            />
          </Form>
          <br />
          <br />
          <div style={{ paddingRight: '6%' }}>
            <table className='table table-striped table-borderless'>
              <thead>
                <tr>
                  <th scope='col'>Department</th>
                  <th scope='col'>Total Hours</th>
                  <th scope='col'>Average Hours</th>
                </tr>
              </thead>
              <tbody>{this.fillTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default withFirebase(DeptHoursPage);
